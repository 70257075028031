import { FC, Fragment } from 'react';

import { CoreNavigationAuthenticatedDesktop } from '@/components/ui/shared/headers/CoreHeader/layouts/CoreNavigationAuthenticated.desktop';
import { CoreNavigationAuthenticatedMobile } from '@/components/ui/shared/headers/CoreHeader/layouts/CoreNavigationAuthenticated.mobile';
import { AccessFrom } from '@/enums/AccessFrom';
import { User } from '@/resources/User';

interface IUserAuthMenuProps {
  accessFrom: AccessFrom;
  user: User;
  onLogout?: () => void;
}
export const UserAuthMenu: FC<IUserAuthMenuProps> = ({ user, onLogout, accessFrom }) => {
  return (
    <Fragment>
      <CoreNavigationAuthenticatedDesktop
        accessFrom={accessFrom}
        display={{ base: 'none', lg: 'block' }}
        currentUser={user}
        onLogout={onLogout}
      />
      <CoreNavigationAuthenticatedMobile
        accessFrom={accessFrom}
        display={{ base: 'flex', lg: 'none' }}
        currentUser={user}
        onLogout={onLogout}
      />
    </Fragment>
  );
};
