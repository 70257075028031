import { Box } from '@chakra-ui/react';
import { t } from 'i18next';
import { FC } from 'react';
import Select, { CSSObjectWithLabel, MultiValue, StylesConfig } from 'react-select';

interface IOptions {
  value: string;
  label: string;
}
export interface IMultiselectDropdown {
  onChange: (item: MultiValue<IOptions>) => void;
  selectedOptions?: Array<IOptions>;
}

const options = [
  { value: 'computer_science', label: 'AI & Computer Science' },
  { value: 'life_sciences', label: 'Life Sciences' },
  { value: 'health_sciences', label: 'Health Sciences' },
  { value: 'social_sciences', label: 'Social Sciences' },
  { value: 'physics', label: 'Physics' },
  { value: 'library_updates', label: 'Library product updates' },
  { value: 'event_updates', label: 'Event products updates' },
];

export const MultiselectDropdown: FC<IMultiselectDropdown> = ({ onChange, selectedOptions }) => {
  const constrolStyles = {
    border: '1px solid #CACCCC',
    borderRadius: 30,
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: '20px',
    backgroundColor: 'white',
    fontFamily: 'IBM Plex Sans',
  };

  const valueContainerStyles = {
    padding: 0,
  };

  const placeholderStyles = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  const indicatorSeparatorStyles = {
    display: 'none',
  };

  const dropdownIndicatorStyles = {
    color: '#000',
    padding: 0,
    marginRight: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const clearIndicatorStyles = {
    color: '#000',
    marginRight: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const optionStyles = {
    cursor: 'pointer',
    ':active': {
      backgroundColor: '#5B1CF4',
    },
  };

  const multiValueStyles = {
    backgroundColor: 'white',
    border: '1px solid #B1B2B2',
    color: 'black',
    borderRadius: 30,
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    marginRight: 5,
    display: 'flex',
    alignItems: 'center',
  };

  const multiValueRemoveStyles = {
    backgroundColor: 'white',
    borderRadius: 30,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: 'white',
    },
  };

  const dropdownStyles: StylesConfig = {
    control: (styles) => {
      return {
        ...styles,
        ...constrolStyles,
      } as CSSObjectWithLabel;
    },
    valueContainer: (styles) => {
      return {
        ...styles,
        ...valueContainerStyles,
      } as CSSObjectWithLabel;
    },
    placeholder: (styles) => {
      return {
        ...styles,
        '@media (max-width: 768px)': {
          ...placeholderStyles,
        },
      } as CSSObjectWithLabel;
    },
    indicatorSeparator: (styles) => {
      return {
        ...styles,
        ...indicatorSeparatorStyles,
      } as CSSObjectWithLabel;
    },
    dropdownIndicator: (styles) => {
      return {
        ...styles,
        ...dropdownIndicatorStyles,
      } as CSSObjectWithLabel;
    },
    clearIndicator: (styles) => {
      return {
        ...styles,
        ...clearIndicatorStyles,
      } as CSSObjectWithLabel;
    },
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? '#5B1CF4' : 'white',
        color: isFocused ? 'white' : '#646666',
        ...optionStyles,
      } as CSSObjectWithLabel;
    },
    multiValue: (styles) => {
      return {
        ...styles,
        ...multiValueStyles,
      } as CSSObjectWithLabel;
    },
    // x button in selected option
    multiValueRemove: (styles) => {
      return {
        ...styles,
        ...multiValueRemoveStyles,
      } as CSSObjectWithLabel;
    },
  };

  return (
    <Select
      placeholder={t('newsletterSubscribe.dropdownPlaceholder') as string}
      options={options}
      isMulti
      instanceId="newsletter-subscribe"
      onChange={onChange}
      value={selectedOptions}
      styles={dropdownStyles}
    />
  );
};
