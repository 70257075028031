import { Box, Button, Flex, FlexProps, Heading, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ErrorMessage } from '@/components/core/inputs/ErrorMessage';
import { MultiselectDropdown } from '@/components/ui/core/MultiselectDropdown/MultiselectDropdown';
import { IToastType } from '@/enums/ToastType';
import { useStores } from '@/hooks/useStores';
import { useToast } from '@/hooks/useToast';
import { EMAIL_REGEX } from '@/utils/constants';

export const NewsletterSubscription: FC<FlexProps> = (props) => {
  const [selectedItems, setSelectedItems] = useState([]);

  const onChange = (selectedOption) => {
    setSelectedItems(selectedOption);
  };

  const { t } = useTranslation();
  const { store } = useStores();
  const toast = useToast();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (attributes) => {
    try {
      await store.request('newsletter_subscription', 'POST', {
        data: {
          type: 'subscription',
          attributes: {
            email: attributes.email,
            interests: selectedItems.map((item) => item.value),
          },
        },
      });

      reset();
      setSelectedItems([]);
      toast({ status: IToastType.SUCCESS, title: t('newsletterSubscribe.emailSuccess') });
    } catch (err) {
      toast({ status: IToastType.ERROR, title: t('newsletterSubscribe.emailError') });
    }
  };

  return (
    <Flex maxW={{ base: 'full', md: '600px', xl: '490px' }} flexDirection="column" {...props}>
      <Heading mb="6" size="m">
        {t('newsletterSubscribe.heading')}
      </Heading>
      <MultiselectDropdown selectedOptions={selectedItems} onChange={onChange} />
      <Box mt="12">
        <InputGroup
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          pb="4"
          borderBottomColor="gray.600"
          borderBottomWidth="1px"
          size="xl"
        >
          <Input
            {...register('email', {
              required: { value: true, message: t('profilePage.email.emailRequiredError') },
              pattern: { value: EMAIL_REGEX, message: t('profilePage.email.emailPatternError') },
            })}
            fontSize="sm"
            type="text"
            inputMode="text"
            aria-invalid={Boolean(errors.email)}
            variant="unstyled"
            placeholder={t('newsletterSubscribe.inputPlaceholder')}
            pr={20}
          />
          <InputRightElement pr="2">
            <Button type="submit" h="5" size="sm" variant="unstyled">
              {t('newsletterSubscribe.inputButtonLabel')}
            </Button>
          </InputRightElement>
        </InputGroup>
        <ErrorMessage name="email" errors={errors} />
      </Box>
    </Flex>
  );
};
