import styled from '@emotion/styled';
import { FC } from 'react';

import { FilledButton } from '@/components/core/Button/variants/FilledButton';
import { H2 } from '@/components/core/Heading';
import { FontFamily } from '@/enums/index';
import { ButtonTheme } from '@/styles/themes/button';
import { BREAKPOINTS } from '@/styles/themes/default';
import { IThemeProps } from '@/styles/themes/types';

interface ICheckYourEmailProps {
  email: string;
  onCloseFlow(): void;
}

const Container = styled.div<IThemeProps>`
  color: white;
  max-width: 390px;
  margin: 0 auto;
  padding: 72px 0 0;
  justify-content: center;
  display: flex;
  flex-direction: column;

  @media (min-width: ${BREAKPOINTS.Desktop.min}px) {
    padding: 0;
  }
`;

const Paragraph = styled.p<IThemeProps>`
  margin: 24px 0 32px;
  font-family: ${({ theme }) => theme.legacy.typography.fontFamily[FontFamily.IBMPlexSans]};
  line-height: 24px;

  @media (min-width: ${BREAKPOINTS.Desktop.min}px) {
    text-align: left;
    margin: 32px 0 55px;
  }
`;

const OkButton = styled(FilledButton)<IThemeProps>`
  background: #b6ffe2;
  margin-top: auto;
  margin-bottom: 0;
  width: 100%;
  justify-content: center;

  &:hover {
    background: #b6ffe2;
  }

  &:active {
    background-color: #94beb7;
  }

  @media (min-width: ${BREAKPOINTS.Desktop.min}px) {
    margin-top: 34px;
    width: 133px;
  }
`;

export const CheckYourEmail: FC<ICheckYourEmailProps> = ({ email, onCloseFlow }) => {
  return (
    <Container>
      <H2>Check your inbox!</H2>
      <Paragraph>
        We&apos;ve sent you a link where you can create your new password if an account with the email {email} exists.
      </Paragraph>
      <OkButton onClick={onCloseFlow} buttonTheme={ButtonTheme.DARK}>
        Ok
      </OkButton>
    </Container>
  );
};
