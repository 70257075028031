import { Skeleton } from '@chakra-ui/react';
import { FC, Fragment } from 'react';

import { UserAuthMenu } from '@/components/ui/shared/headers/CoreHeader/components/UserAuthMenu';
import { CoreNavigationAuthenticationDesktop } from '@/components/ui/shared/headers/CoreHeader/layouts/CoreNavigationAuthentication.desktop';
import { CoreNavigationAuthenticationMobile } from '@/components/ui/shared/headers/CoreHeader/layouts/CoreNavigationAuthentication.mobile';
import { AccessFrom } from '@/enums/AccessFrom';
import useSession from '@/hooks/useSession';

interface IUserMenuProps {
  onLogout: () => void;
  accessFrom: AccessFrom;
}

export const UserMenu: FC<IUserMenuProps> = ({ accessFrom, ...props }) => {
  const { user, isSessionLoading } = useSession();

  if (isSessionLoading) {
    return <Skeleton w="32px" h="32px" borderRadius="full" ml={5} />;
  }

  if (!user) {
    return (
      <Fragment>
        <CoreNavigationAuthenticationDesktop accessFrom={accessFrom} display={{ base: 'none', lg: 'block' }} />
        <CoreNavigationAuthenticationMobile accessFrom={accessFrom} display={{ base: 'flex', lg: 'none' }} />
      </Fragment>
    );
  }

  return <UserAuthMenu accessFrom={accessFrom} user={user} {...props} />;
};
