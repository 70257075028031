import { Menu, MenuButton, MenuButtonProps, MenuItem, MenuList } from '@chakra-ui/react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import UserIcon from '@/assets/icons/ic-user.svg';

import { NavLink } from '@/components/ui/shared/links/NavLink/NavLink';
import { AccessFrom } from '@/enums/AccessFrom';

interface INavProps extends MenuButtonProps {
  accessFrom: AccessFrom;
}
export const CoreNavigationAuthenticationDesktop: FC<INavProps> = ({ accessFrom, ...props }) => {
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <Menu computePositionOnMount variant="navigation" colorScheme="blackAlpha" placement="bottom-end">
      <MenuButton
        display="flex"
        justifyContent="center"
        alignItems="center"
        aria-label="Profile button"
        p={1}
        ml="5"
        {...props}
      >
        <UserIcon />
      </MenuButton>
      <MenuList color="white">
        <NextLink
          href={`/log-in?redirectUrl=${router.asPath === '/log-in' ? '/' : router.asPath}&accessFrom=${accessFrom}`}
          passHref
          legacyBehavior
        >
          <NavLink as={MenuItem} px={4} isActiveLink={router.asPath.startsWith('/log-in')}>
            {t('action.logIn')}
          </NavLink>
        </NextLink>

        <NextLink href={`/create-account?accessFrom=${accessFrom}`} passHref legacyBehavior>
          <NavLink
            as={MenuItem}
            px={4}
            order={{ base: 1, lg: 3 }}
            isActiveLink={router.asPath.startsWith('/create-account')}
          >
            {t('action.createAccount')}
          </NavLink>
        </NextLink>
      </MenuList>
    </Menu>
  );
};
