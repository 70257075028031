import { BoxProps, Flex } from '@chakra-ui/react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { NavLink } from '@/components/ui/shared/links/NavLink/NavLink';
import { AccessFrom } from '@/enums/AccessFrom';

interface INavProps extends BoxProps {
  accessFrom: AccessFrom;
}

export const CoreNavigationAuthenticationMobile: FC<INavProps> = ({ accessFrom, ...props }) => {
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <Flex {...props} flexDirection="column">
      <NextLink
        href={`/log-in?redirectUrl=${router.asPath === '/log-in' ? '/' : router.asPath}&accessFrom=${accessFrom}`}
        passHref
        legacyBehavior
      >
        <NavLink isActiveLink={router.asPath.startsWith('/log-in')}>{t('action.logIn')}</NavLink>
      </NextLink>
      <NextLink href={`/create-account?accessFrom=${accessFrom}`} passHref legacyBehavior>
        <NavLink ml={[null, null, 0, 0]} isActiveLink={router.asPath.startsWith('/create-account')}>
          {t('action.createAccount')}
        </NavLink>
      </NextLink>
    </Flex>
  );
};
